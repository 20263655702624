//Відобрадкння мобільного меню
function showMobileMenu() {
    let mobMenu = document.querySelector('.main-nav.main-nav3');
    let overlay3 = document.querySelector('.overlay3');
    if (!mobMenu.classList.contains('active')) {
        mobMenu.classList.add('active')
        overlay3.classList.add('disp')
    } else {
        mobMenu.classList.remove('active')
        overlay3.classList.remove('disp')
    }

}
//Режим мобільного пошуку
function searchMod() {
    let serachMod = document.getElementById('serach-mod');
    let searchForm = document.getElementById('mob-search');
    if (!serachMod.querySelector('.check-cart.check-cart-ontop').classList.contains('serach-mod')) {
        serachMod.querySelector('.check-cart.check-cart-ontop').classList.add('serach-mod');
        serachMod.querySelector('.mini-cart-box.lit-cart-top.cart-top').classList.add('serach-mod');
        searchForm.style.opacity = 1;
        searchForm.style.visibility = 'visible';
    } else {
        serachMod.querySelector('.check-cart.check-cart-ontop').classList.remove('serach-mod');
        serachMod.querySelector('.mini-cart-box.lit-cart-top.cart-top').classList.remove('serach-mod');
        searchForm.style.opacity = 0;
        searchForm.style.visibility = 'hidden';
        if (document.querySelector('#mob-search input[name="search"]').value != '') {
            document.getElementById('mob-search').submit();
        }

    }
}

window.showMobileMenu = showMobileMenu;
window.searchMod = searchMod;